<template>
  <div class="help-text mb-5">
    <v-dialog v-if="helpImage" v-model="dialog" width="310">
      <template v-slot:activator="{ on }">
        <a v-on="on"><slot></slot> ↗</a>
      </template>
      <img :src="helpImageSrc" :alt="helpImage">
    </v-dialog>
    <span v-else><slot></slot></span>
  </div>
</template>

<script>
export default {
  name: 'Helptext',
  props: {
    helpImage: String,
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    helpImageSrc() {
      return require(`@/assets/images/item-templates/have-a-chat/${this.helpImage}`)
    }
  },
}
</script>

<style lang="scss" scoped>
.help-text {
  font-size: small;
  color: rgba(0,0,0,0.6);
}
</style>