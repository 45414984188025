<template>
  <v-app class="form-container">
    <TextBox v-model="rubric" label="Introduction" />
    <Helptext helpImage="introduction.png">This is displayed in the middle of the introduction page.</Helptext>

    <TextBox v-model="title" label="Chat Title" />
    <Helptext helpImage="task.png">This is displayed at the top of the chat page.</Helptext>

    <TextBox v-model="static_text" label="Response Prompt" />
    <Helptext helpImage="task-prompt.png">This is the prompt above the test-taker input field.</Helptext>

    <TextBox v-model="peerGradingInstructionTitle" label="Peer Grading Title" />
    <Helptext helpImage="peer-grading-title.png">This is the title at the top of the peer-grading.</Helptext>

    <section class="mt-6" v-for="field, i in messageFields" :key="`field-${i}`">
      <v-divider class="my-3"></v-divider>

      <label class="item-label mt-6 pb-4">Chat Message #{{i + 1}}</label>

      <div class="mt-4">
        <TextBox v-model="field.metadatas.name" label="Name" />
        <TextBox v-model="field.question" label="Text" />

        <template v-if="field.hasInstructions">
          <label class="item-label mt-6">Instructions</label>
          <Textarea :rows="4" v-model="field.metadatas.instructions" :parser="sanitiseInstructions" />
          <Helptext class="negative-top-margin" helpImage="instructions.png">This is below the Static Text prompt.</Helptext>
          <div class="negative-top-margin mb-4"><small>
            ✅ Write each instruction on a separate line.<br />
            📳 In the app, the first line you write will be the prompt to the left of the instructions.<br />
            <template v-if="field.hasadvisedWordLimitPrompt">
              🔣 The last line will automatically be: "{{ field.advisedWordLimitPrompt }}", so no need to write that.
            </template>
          </small></div>
        </template>

        <section v-if="field.hasPeerGradingPrompt">
          <TextBox v-model="peerGradingFields[0].question" label="Peer Grading Prompt #1" />
          <TextBox v-model="peerGradingFields[1].question" label="Peer Grading Prompt #2" />
          <Helptext helpImage="peer-grading.png">This is the prompt for the peer-grader.</Helptext>
        </section>

      </div>
    </section>
  </v-app>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import TextBox from '@/components/item/TextBox'
import Textarea from '@/components/item/Textarea'
import Helptext from '@/components/item/Helptext.vue'
import { v4 as uuidv4 } from 'uuid'

// An array of defaults to generate the message fields in the form
const defaultMessageFields = [
  {
    position: 0,
    metadatas: {
      name: '',
      instructions: '',
    },
    hasInstructions: true,
  },
  {
    position: 1,
    metadatas: {
      name: '',
      instructions: '',
    },
    hasInstructions: true,
    hasPeerGradingPrompt: true,
  },
  {
    position: 2,
    metadatas: {
      name: '',
    },
  },
].map(row => ({
  ...row,
  question: '',
  question_id:'',
}))

// When updating an item, hydrate the messageFields object with data from the questions array.
const hydrateMessageFields = ({ payload: { questions }, messageFields }) => {
  const hydrate = (({ index, hasInstructions }) => {
    const testTakerQuestion = questions.find(({ data_point, position }) => !data_point && position === index)
    const formField = messageFields.find(({ position }) => position === index)
    formField.question = testTakerQuestion.question
    formField.question_id = testTakerQuestion.question_id
    formField.metadatas = testTakerQuestion.metadatas

    if (hasInstructions) {
      const instructions = testTakerQuestion.metadatas?.instructions
      if (!instructions) return

      // If the last row in the instructions array is not an instructions prompt, pass.
      if (!instructions.at(-1).startsWith('write')) return

      // Separate the word limit prompt from the rest of the instructions array.
      formField.advisedWordLimitPrompt = instructions.pop()
      formField.hasadvisedWordLimitPrompt = true
      formField.metadatas.instructions = instructions.join('\n')
    }
  })

  hydrate({ index: 0, hasInstructions: true })
  hydrate({ index: 1, hasInstructions: true })
  hydrate({ index: 2, hasInstructions: false })
}

// Update the peerGradingFields object with data from the questions array.
const hydratePeerGradingFields = ({ payload: { questions }, peerGradingFields }) => {
  const hydrate = (({ index }) => {
    const peerGradingQuestion = questions.find(({ data_point, position }) => typeof data_point === 'string' && position === index)
    const peerGradingField = peerGradingFields.find(({ position }) => position === index)

    peerGradingField.question = peerGradingQuestion.question
    peerGradingField.question_id = peerGradingQuestion.question_id
  })

  hydrate({ index: 0 })
  hydrate({ index: 1 })
  hydrate({ index: 2 })
}

// Note: wordLimits is available only when creating new items
const hydrateAdvisedWordLimits = ({ wordLimits, messageFields }) => {
  const hydrate = (({ index }) => {
    const messageField = messageFields.find(({ position }) => position === index)
    const advisedWordLimits = wordLimits.advised.find(({ position }) => position === index)
    if (!advisedWordLimits) return

    const { min, max } = advisedWordLimits
    messageField.advisedWordLimitPrompt = `write ${min}—${max} words.`
    messageField.hasadvisedWordLimitPrompt = true
    messageField.metadatas = {
      ...messageField.metadatas,
      min_word_limit: wordLimits.mandatory.min,
      max_word_limit: wordLimits.mandatory.max,
    }
  })
  hydrate({ index: 0 })
  hydrate({ index: 1 })
}

// The questions[] array is sent to the API as part of the item payload.
const getQuestionsData = (data) => {
  const { messageFields, peerGradingFields, peerGradingInstructionTitle } = data
  const questions = []

  // Push the message rows to the questions array
  const firstMessage = messageFields.find(({ position }) => position === 0)
  questions.push({
    position: 0,
    solution: [],
    question_id: firstMessage.question_id || uuidv4(),
    question: firstMessage.question,
    metadatas: {
      ...firstMessage.metadatas,
      instructions: [ ...firstMessage.metadatas.instructions.split(/\r?\n/), firstMessage.advisedWordLimitPrompt ],
    }
  })

  const secondMessage = messageFields.find(({ position }) => position === 1)
  questions.push({
    position: 1,
    question_id: secondMessage.question_id || uuidv4(),
    question: secondMessage.question,
    solution: [],
    metadatas: {
      ...secondMessage.metadatas,
      instructions: [ ...secondMessage.metadatas.instructions.split(/\r?\n/), secondMessage.advisedWordLimitPrompt ],
    }
  })

  const thirdMessage = messageFields.find(({ position }) => position === 2)
  questions.push({
    position: 2,
    question_id: thirdMessage.question_id || uuidv4(),
    question: thirdMessage.question,
    solution: [],
    metadatas: {
      ...thirdMessage.metadatas,
    }
  })

  // Push the peer-grading rows to the questions array
  const firstPeerGradingPrompt = peerGradingFields.find(({ position }) => position === 0)
  questions.push({
    position: 0,
    question_template: 'QUESTION_WITH_RESPONSE',
    data_point: 'Q_CONTEXTUAL_BINARY',
    solution: [],
    question_id: firstPeerGradingPrompt.question_id || uuidv4(),
    question: firstPeerGradingPrompt.question,
    metadatas: {
      title: peerGradingInstructionTitle,
    }
  })

  const secondPeerGradingPrompt = peerGradingFields.find(({ position }) => position === 1)
  questions.push({
    position: 1,
    question_template: 'QUESTION_WITH_RESPONSE',
    data_point: 'Q_CONTEXTUAL_BINARY_2',
    solution: [],
    question_id: secondPeerGradingPrompt.question_id || uuidv4(),
    question: secondPeerGradingPrompt.question,
    metadatas: {
      title: peerGradingInstructionTitle,
    }
  })

  const thirdPeerGradingPrompt = peerGradingFields.find(({ position }) => position === 2)
  questions.push({
    position: 2,
    question_template: 'STAR_RATING_WITH_RESPONSE',
    data_point: 'RATING_OVERALL_5STAR',
    solution: [],
    question_id: thirdPeerGradingPrompt.question_id || uuidv4(),
    question: 'How well did they do?',
    metadatas: {
      title: peerGradingInstructionTitle,
    }
  })

  return questions
}

export default {
  components: {
    TextBox,
    Textarea,
    Helptext,
  },
  data: () => ({
    title: '',
    rubric: '',
    static_text: '',
    advisedWordLimitPrompt: '',
    messageFields: JSON.parse(JSON.stringify(defaultMessageFields)),
    peerGradingInstructionTitle: '',
    peerGradingFields: [...new Array(3)].map((_, i) => ({
      position: i,
      question: '',
      question_id: '',
    })),
  }),
  computed: {
    ...mapState('item', ['payload', 'isUpdate', 'wordLimits']),
    ...mapGetters('account', ['user']),
  },
  methods: {
    sanitiseInstructions(value) {
      const instructions = value
        .split(/\r?\n/)
        .map(s => s.trim())
        .filter(s => s !== '')
        .join('\n')
      return instructions
    },
    onSubmit() {
      const data = {
        template_type: 'HAVE_A_CHAT',
        title: this.title,
        rubric: this.rubric,
        static_text: this.static_text,
        questions: getQuestionsData(this),
      }
      this.$store.commit('item/setPayload', data)
    }
  },
  beforeMount() {
    if (this.isUpdate) {
      const { title, rubric, static_text, questions } = this.payload
      this.title = title
      this.rubric = rubric
      this.static_text = static_text

      const firstPeerGradingPrompt = questions.find(({ position, data_point }) => position === 0 && typeof data_point === 'string')
      this.peerGradingInstructionTitle = firstPeerGradingPrompt && firstPeerGradingPrompt.metadatas.title

      hydrateMessageFields(this)
      hydratePeerGradingFields(this)
    }
    else {
      hydrateAdvisedWordLimits(this)
    }
  },
}
</script>

<style lang='scss' scoped>
label {
  color: #264d98;
  &.ghost {
    cursor: not-allowed;
    color: rgba(0, 0, 0, 0.4);
    background-color: rgba(230, 230, 230, 0.4);
    border: none;
  }
}

.form-container {
  max-width: 550pt;
}

::v-deep {
  .v-application--wrap {
    min-height: auto;
  }
  .textbox-container {
    margin: 10px 0px;
  }
  .negative-top-margin {
    margin-top: -15px;
  }
}
</style>
